import { parseISO } from "date-fns";
import { capitalize } from "lodash";

export function getPersonDisplayName(
  person: Pick<
    FilingPerson,
    "firstName" | "middleName" | "lastName" | "suffix" | "uploadsBundleUrl"
  >
) {
  let name = person.firstName;
  if (person.middleName) {
    name += ` ${person.middleName}`;
  }
  name += ` ${person.lastName}`;
  if (person.suffix) {
    name += ` ${person.suffix}`;
  }
  return name;
}

export interface FormattedReferredUser {
  id: UUID;
  name: string;
  email: string;
  emailVerified: boolean;
  isArchived: boolean;
  completionStatus: string;
  completionStatusRaw: string;
  totalProgress: string;
  enableCreditReport: boolean;
  registeredAt: Date;
  lastLoginAt: Date;
  uploadsBundleUrl: string;
  uploadsBundleGeneratedAt: Date;
  lawFirmContact: string;
  documentsDueDate: Date;
}

export function getFormattedReferredUser(
  user: ReferredUser
): FormattedReferredUser {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    emailVerified: user.emailVerified,
    isArchived: user.isArchived,
    completionStatus: capitalize(
      user.completionStatus.toLocaleLowerCase().replace("_", " ")
    ),
    completionStatusRaw: user.completionStatus,
    totalProgress: `${user.totalProgress}%`,
    registeredAt: parseISO(user.createdAt),
    lastLoginAt: user.lastLogin ? parseISO(user.lastLogin) : null,
    uploadsBundleUrl: user.uploadsBundleUrl,
    uploadsBundleGeneratedAt: user.uploadsBundleGeneratedAt
      ? parseISO(user.uploadsBundleGeneratedAt)
      : null,
    enableCreditReport: user.enableCreditReport,
    lawFirmContact: user.lawFirmContact,
    documentsDueDate: user.documentsDueDate
      ? parseISO(user.documentsDueDate)
      : null,
  };
}
