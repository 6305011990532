import cn from "classnames";
import { Card } from "components/Card";
import { RootOverlay } from "components/DateInput/RootOverlay";
import { debounce, isFunction } from "lodash";
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCheckMounted } from "utils/hooks";
import css from "./TypeAhead.module.scss";

interface TypeAheadProps<T> {
  /** The CSS selector of the input to attach to. */
  inputSelector: string;

  /** Function called to update suggestions when the input's value changes.
   *
   * It is internally debounced to prevent excessive updates.
   */
  getSuggestions: (query: string) => T[] | Promise<T[]>;

  /** Function called when the user selects a suggestion from the list. */
  onSelect: (suggestion: T) => any;

  /** Render function for displaying suggestions in the  list. */
  formatSuggestion?: (suggestion: T) => ReactNode;

  /** Optional content to display at the bottom of the list. */
  footer?: ReactNode | FC<{ suggestions: T[] }>;
}

/** Attaches a dropdown which presents suggestions below inputs based on partial values. */
export default function TypeAhead<T>({
  inputSelector,
  getSuggestions,
  formatSuggestion = (x) => x,
  onSelect,
  footer: Footer,
}: TypeAheadProps<T>) {
  const isMounted = useCheckMounted();
  const firstSuggestionRef = useRef(null);
  const cleared = useRef(Date.now());
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const checkMounted = useCheckMounted();

  const clear = useCallback(() => {
    if (!isMounted()) return;
    setSuggestions([]);
    cleared.current = Date.now();
  }, [isMounted]);

  const updateQuery = useCallback(
    debounce(
      async (query: string) => {
        const requestTime = Date.now();
        const newSuggestions = await getSuggestions(query);

        // Check for request invalidation
        if (requestTime < cleared.current) return;
        if (!checkMounted()) return;

        setSuggestions(newSuggestions);
      },
      300,
      { maxWait: 1000, leading: true }
    ),
    [getSuggestions, checkMounted]
  );

  const focusAutocomplete = () =>
    (document.querySelector(inputSelector) as HTMLElement | undefined)?.focus();

  const focusFirstSuggestion = () => firstSuggestionRef.current?.focus();

  const checkBlur = useCallback(() => {
    setTimeout(() => {
      if (
        !(
          document.activeElement.matches(`.${css.addressSuggestion}`) ||
          document.activeElement.matches(inputSelector)
        )
      ) {
        clear();
      }
    }, 1);
  }, [clear, inputSelector]);

  useEffect(() => {
    const updateQueryOnChange = (e: Event) => {
      const el = e.target as HTMLInputElement;
      updateQuery(el.value);
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (!suggestions.length) return;
      if ((e.key === "Tab" && !e.shiftKey) || e.key === "ArrowDown") {
        e.preventDefault();
        focusFirstSuggestion();
      }
    };

    const el = document.querySelector(inputSelector) as
      | HTMLInputElement
      | undefined;

    if (!el) {
      /* eslint-disable-next-line no-console */
      console.warn(
        `Could not find TypeAhead input with selector ${inputSelector}`
      );
      return;
    }

    el.addEventListener("input", updateQueryOnChange);
    el.addEventListener("blur", checkBlur);
    el.addEventListener("keydown", onKeyDown);

    return () => {
      el.removeEventListener("input", updateQueryOnChange);
      el.removeEventListener("blur", checkBlur);
      el.removeEventListener("keydown", onKeyDown);
    };
  }, [checkBlur, clear, inputSelector, suggestions.length, updateQuery]);

  return (
    !!suggestions.length && (
      <RootOverlay
        attachTo={inputSelector}
        placement="bottom-start"
        onBlur={checkBlur}
        modifiers={[
          { name: "flip", enabled: true },
          { name: "offset", options: { offset: [0, 8] } },
        ]}
      >
        <Card className={css.suggestions}>
          {suggestions.map((s, i) => (
            <button
              className={cn("btn", "unstyled", css.addressSuggestion)}
              key={i}
              ref={i === 0 ? firstSuggestionRef : undefined}
              onKeyDown={(e) => {
                const el = e.target as HTMLElement;

                if (e.key === "ArrowDown" && i !== suggestions.length - 1) {
                  e.preventDefault();
                  (el.nextElementSibling as HTMLElement | undefined)?.focus();
                }

                if (e.key === "ArrowUp") {
                  e.preventDefault();
                  if (i === 0) {
                    focusAutocomplete();
                  } else {
                    (
                      el.previousElementSibling as HTMLElement | undefined
                    )?.focus();
                  }
                }

                if (e.key === "Tab") {
                  if (i === suggestions.length - 1 && !e.shiftKey) {
                    focusAutocomplete();
                  }

                  if (i === 0 && e.shiftKey) {
                    e.preventDefault();
                    focusAutocomplete();
                  }
                }
              }}
              onClick={async () => {
                await onSelect(s);
                focusAutocomplete();
                clear();
              }}
            >
              {formatSuggestion(s)}
            </button>
          ))}

          {isFunction(Footer) ? <Footer suggestions={suggestions} /> : Footer}
        </Card>
      </RootOverlay>
    )
  );
}
