import { ControlledFileInput } from "components/FileInput";
import { HiddenInput } from "components/HiddenInput";
import { NumberInput } from "components/NumberInput";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { TaxReturnTypeChoice } from "globalTypes";
import { last } from "lodash";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { useQueryParams } from "utils/hooks";
import { mixed, number, object, string } from "yup";
import { ReturnLabels } from "../utils";

export const UploadSchema = object({
  uploadFile: mixed<File | UploadedFile>().required(
    "Please upload a PDF copy of this return."
  ),
  _type: mixed<TaxReturnTypeChoice>()
    .required("Please select what type of return this is.")
    .oneOf(Object.values(TaxReturnTypeChoice)),
  returnYear: number()
    .max(new Date().getFullYear() - 1, "Return year can not be in the future.")
    .min(1920, "Return year can not be before 1920.")
    .required("Please enter the tax year this return was filed for."),
  otherDescription: string().when("_type", {
    is: TaxReturnTypeChoice.Other,
    then: string().required(
      "Please enter a description of what this return is for."
    ),
    otherwise: string().nullable(),
  }),
}).required();

export type UploadFormState = ReturnType<typeof UploadSchema.validateSync>;

function stringIsReturnType(type: string): type is TaxReturnTypeChoice {
  return Object.values(TaxReturnTypeChoice as any).indexOf(type) >= 0;
}

export default function Upload({ isEdit, navProps }: WizardRouteChildProps) {
  const { _type, year: urlYear } = useQueryParams();
  const form = useFormContext<UploadFormState>();
  const [type] = useState(_type || form.getValues()._type);
  const [year] = useState(parseInt(urlYear) || form.getValues().returnYear);
  const uploadFile = form.watch("uploadFile");
  const typeHasDefault = stringIsReturnType(type);
  const typeValue = form.watch("_type") as TaxReturnTypeChoice;

  return (
    <>
      <div className="form-row">
        {!year && (
          <NumberInput
            name="returnYear"
            integerLimit={4}
            label="Return year"
            placeholder="e.g. 2018"
            autoFocus={!isEdit}
          />
        )}
        {!typeHasDefault && (
          <Select
            name="_type"
            label="What type of return is this?"
            options={[
              {
                value: TaxReturnTypeChoice.FederalIncome,
                label: "Federal Income",
              },
              {
                value: TaxReturnTypeChoice.StateIncome,
                label: "State Income",
              },
              {
                value: TaxReturnTypeChoice.LocalIncome,
                label: "Local Income",
              },
              { value: TaxReturnTypeChoice.Other, label: "Other" },
            ]}
          />
        )}
      </div>
      {typeValue === TaxReturnTypeChoice.Other && (
        <div className="form-row">
          <TextInput
            name="otherDescription"
            label="Describe the type of tax return"
            maxLength={255}
            rules={{ required: true }}
          />
        </div>
      )}
      <p className="required">
        Please upload{" "}
        {stringIsReturnType(type) && !!year
          ? `your ${ReturnLabels[type].toLowerCase()} tax return
            from ${year}`
          : "this return"}
        .
      </p>

      {typeHasDefault && <HiddenInput name="_type" value={_type} />}
      {!!year && <HiddenInput name="returnYear" value={year} />}

      <ControlledFileInput
        name="uploadFile"
        fileName={last(uploadFile?.name.split("/")) || undefined}
        downloadUrl={uploadFile?.url || undefined}
        required="Please upload a PDF copy of this return."
        accept=".pdf"
        Icon={(uploadFile || form.watch("uploadFile")) && FaCheckCircle}
        helpText={last(uploadFile?.name.split("/"))}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
